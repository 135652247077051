<template>
  <div class="q-pa-sm">
    <task-queues
      :options="options"
      @select="onRowDblClick"
    />
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import TaskQueues from '../../components/queue/TaskQueues.vue'

export default {
  name: 'AllocationQueue',
  components: {
    TaskQueues
  },
  computed: {
    options () {
      return {
        type: 'allocation',
        title: this.$t('Allocation Queues'),
        service: this.$service.pickingQueue,
        adapters: ['allocation']
      }
    }
  },
  methods: {
    ...mapMutations([
      'setPickingQueue'
    ]),
    onRowDblClick ({ item, toFilters }) {
      this.setPickingQueue(item)
      const path = `/workstation/queues/picking/entity/${item.id}${toFilters ? '?start=true' : ''}`
      this.$router.push(path)
    }
  }
}
</script>
